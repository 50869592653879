import {useEffect, useState} from "react";
import styles from "../../styles/auditor/desktop/AuditorDashboardDesktop.module.css";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import {BiFilterAlt} from "@react-icons/all-files/bi/BiFilterAlt";
import {AUDITOR_DASHBOARD_OPTION_LABELS, AUDITOR_DASHBOARD_OPTIONS} from "./consts";
import OverviewSection from "./OverviewSection";
import {getMainCategories, getOrganizations} from "../../APIs/auditor_dashboard";
import CreatableSelect from "react-select/creatable";
import FinancialSection from "./FinancialSection";
import SellersSection from "./SellersSection";
import PerformanceSection from "./PerformanceSection";


const DashboardDesktop = ({role}) => {
    const [option, setOption] = useState(AUDITOR_DASHBOARD_OPTIONS.OVERVIEW);
    const [organizations, setOrganizations] = useState([{name: "همه"}]);
    const [mainCategories, setMainCategories] = useState(["همه"]);

    // Filters
    const [selectedOrganization, setSelectedOrganization] = useState("all");
    const [selectedMainCategory, setSelectedMainCategory] = useState("all");
    const [selectedFromDate, setSelectedFromDate] = useState("all");
    const [selectedToDate, setSelectedToDate] = useState("all");

    const [filters, setFilters] = useState({
        organization: "all",
        mainCategory: "all",
        fromDate: "all",
        toDate: "all"
    });

    const applyFilters = () => {
        setFilters({
            organization: selectedOrganization === "همه" ? "all" : selectedOrganization,
            mainCategory: selectedMainCategory === "همه" ? "all" : selectedMainCategory,
            fromDate: selectedFromDate,
            toDate: selectedToDate
        })
    };

    const resetFilters = () => {
        setSelectedOrganization("all");
        setSelectedMainCategory("all");
        setSelectedFromDate("all");
        setSelectedToDate("all");

        setFilters({
            organization: "all",
            mainCategory: "all",
            fromDate: "all",
            toDate: "all"
        })
    };

    // organizations
    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const response = await getOrganizations();
                setOrganizations([organizations[0], ...response.data]);
            } catch (error) {
                console.error('Error fetching orgs:', error);
            }
        }

        if (role === "auditor") {
            fetchOrganizations();
        }
    }, []);

    // main categories
    useEffect(() => {
        const fetchMainCategories = async () => {
            try {
                const response = await getMainCategories(filters);
                setMainCategories([mainCategories[0], ...response.data.main_categories]);
            } catch (error) {
                console.error('Error fetching main categories:', error);
            }
        }

        fetchMainCategories();

    }, [filters]);

    const convertPersianToEnglishDigits = (input) => {
        const persianDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
        const englishDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

        return input.toString().replace(/[۰-۹]/g, (w) => {
            return englishDigits[persianDigits.indexOf(w)];
        });
    };


    return (
        <div className={styles.main_container}>

            <div className={styles.top_bar}>
                <span className={styles.header_title}>گزارش‌ها</span>
                <div className={styles.vertical_divider}></div>
                <button
                    className={styles.header_button}
                    disabled={option === AUDITOR_DASHBOARD_OPTIONS.OVERVIEW}
                    onClick={() => setOption(AUDITOR_DASHBOARD_OPTIONS.OVERVIEW)}>
                    {AUDITOR_DASHBOARD_OPTION_LABELS[AUDITOR_DASHBOARD_OPTIONS.OVERVIEW]}
                </button>
                <button
                    className={styles.header_button}
                    disabled={option === AUDITOR_DASHBOARD_OPTIONS.FINANCIAL}
                    onClick={() => setOption(AUDITOR_DASHBOARD_OPTIONS.FINANCIAL)}>
                    {AUDITOR_DASHBOARD_OPTION_LABELS[AUDITOR_DASHBOARD_OPTIONS.FINANCIAL]}
                </button>
                {["auditor", "manager"].includes(role) &&
                    <button
                        className={styles.header_button}
                        disabled={option === AUDITOR_DASHBOARD_OPTIONS.SELLERS}
                        onClick={() => setOption(AUDITOR_DASHBOARD_OPTIONS.SELLERS)}>
                        {AUDITOR_DASHBOARD_OPTION_LABELS[AUDITOR_DASHBOARD_OPTIONS.SELLERS]}
                    </button>
                }
                {role === "auditor" &&
                    <button
                        className={styles.header_button}
                        disabled={option === AUDITOR_DASHBOARD_OPTIONS.PERFORMANCE_REPORT}
                        onClick={() => setOption(AUDITOR_DASHBOARD_OPTIONS.PERFORMANCE_REPORT)}>
                        {AUDITOR_DASHBOARD_OPTION_LABELS[AUDITOR_DASHBOARD_OPTIONS.PERFORMANCE_REPORT]}
                    </button>
                }
            </div>

            <div className={styles.reports_and_filters_container}>
                <div className={styles.filters_panel}>

                    <div className={styles.section_header_container}>
                        <BiFilterAlt className={styles.section_icon}/>
                        <span className={styles.section_header}>
                            فیلترها
                        </span>
                    </div>

                    <div className={styles.filters_selects_container}>
                        {role === "auditor" &&
                            <>
                                <span className={styles.filter_select_subject}>سازمان:</span>
                                <div className={styles.filter_select_container}>
                                    <CreatableSelect
                                        className={styles.filters_select}
                                        value={{
                                            label: selectedOrganization === "all" ? "همه" : selectedOrganization,
                                            value: selectedOrganization
                                        }}
                                        onChange={(selectedOption) => setSelectedOrganization(selectedOption ? selectedOption.value : '')}
                                        options={organizations.map((organization) => ({
                                            label: organization.name,
                                            value: organization.name,
                                        }))}
                                        isClearable={false}
                                    />
                                </div>
                            </>
                        }
                        <span className={styles.filter_select_subject}>گروه کالا:</span>
                        <div className={styles.filter_select_container}>
                            <CreatableSelect
                                className={styles.filters_select}
                                value={{
                                    label: selectedMainCategory === "all" ? "همه" : selectedMainCategory,
                                    value: selectedMainCategory
                                }}
                                onChange={(selectedOption) => setSelectedMainCategory(selectedOption ? selectedOption.value : '')}
                                options={mainCategories.map((mainCategory) => ({
                                    label: mainCategory,
                                    value: mainCategory,
                                }))}
                                isClearable={false}
                            />
                        </div>
                        <span className={styles.filter_select_subject}>از تاریخ:</span>
                        <div className={styles.filter_select_container}>
                            <DatePicker
                                calendar={persian}
                                locale={persian_fa}
                                inputClass={styles.customDatePicker}
                                containerClassName={styles.customDatePickerDropdown}
                                containerStyle={{display: "flex", width: '55%'}}
                                value={selectedFromDate}
                                onChange={(date) => {
                                    const englishDate = convertPersianToEnglishDigits(date);
                                    setSelectedFromDate(englishDate);
                                }}
                            />
                        </div>
                        <span className={styles.filter_select_subject}>تا تاریخ:</span>
                        <div className={styles.filter_select_container}>
                            <DatePicker
                                calendar={persian}
                                locale={persian_fa}
                                inputClass={styles.customDatePicker}
                                containerClassName={styles.customDatePickerDropdown}
                                containerStyle={{display: "flex", width: '55%'}}
                                value={selectedToDate}
                                onChange={(date) => {
                                    const englishDate = convertPersianToEnglishDigits(date);
                                    setSelectedToDate(englishDate);
                                }}
                            />
                        </div>
                    </div>

                    <div className={styles.buttons_container}>
                        <button className={styles.filter_button} onClick={applyFilters}>
                            اعمال فیلترها
                        </button>
                        <button className={styles.reset_filter_button} onClick={resetFilters}>
                            حذف فیلترها
                        </button>
                    </div>
                </div>


                {option === AUDITOR_DASHBOARD_OPTIONS.OVERVIEW &&
                    <OverviewSection
                        filters={filters}
                        role={role}
                    />
                }

                {option === AUDITOR_DASHBOARD_OPTIONS.FINANCIAL &&
                    <FinancialSection
                        filters={filters}
                        role={role}
                    />
                }

                {option === AUDITOR_DASHBOARD_OPTIONS.SELLERS &&
                    <SellersSection
                        filters={filters}
                        role={role}
                    />
                }

                {role === "auditor" && option === AUDITOR_DASHBOARD_OPTIONS.PERFORMANCE_REPORT &&
                    <PerformanceSection
                        filters={filters}
                    />
                }

            </div>

        </div>
    )
        ;
}

export default DashboardDesktop;
